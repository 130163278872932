.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
	background-color: #131b26 !important;
}

.rmdp-month-picker{
    background-color: #161a1e !important;
}

.rmdp-container {
	width: 100%;
}
